import { instance as apiService } from ".";
import { handleErrors } from "./utils";

export const newsLetterAPI = async (data) => {
  try {
    await apiService.post('/api/rest/v1/newsletter/', data);
    return true;
  } catch (error) {
    handleErrors(error);
  }
}

export const unsubscribenewsLetterAPI = async (data) => {
  try {
    await apiService.post('/api/rest/v1/newsletter/unsubscribe/', data);
    return true;
  } catch (error) {
    handleErrors(error);
  }
}

export const loggingAPI = async (payload) => {
  try {
    await apiService.post("/api/rest/v1/logging/", payload)
  } catch (error) {
    console.error("Logging Api Failed ", error);
  }
}

export const setCurrencyAPI = async (payload) => {
  try {
    await apiService.post('/api/rest/v1/currencies/setcurrency/', payload);
  } catch (error) {
    handleErrors(error);
  }
}

export const searchAPI = async (query) => {
  try {
    const res = await apiService.get(`/api/rest/v1/search/?q=${query}`);
    return res.data;
  } catch (error) {
    handleErrors(error);
  }
}

export const pincodeAPI = async (productSlug, postal_code) => {
  try {
    const res = await apiService.get(
      `/api/rest/v1/products/${productSlug}/delivery-time/`,
      { params: { postal_code } }
    );
    return res.data;
  } catch (error) {
    handleErrors(error);
  }
}

export const postReview = async (data) => {
  const formData = new FormData();
  formData.append('product', data.product);
  formData.append('reviewer_name', data.reviewer_name);
  formData.append('rating_score', data.rating_score);
  formData.append('review_text', data.review_text);
  formData.append('review_image', data.review_image);

  try {
    await apiService.post("/api/rest/v1/reviews/", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return true;
  } catch (error) {
    handleErrors(error)
  }
};

export const validatePhoneApi = async (data, hideError) => {
  try {
    await apiService.post("/api/rest/v1/validate-mobile-number/", data);
    return true
  } catch (error) {
    if (!hideError) handleErrors(error)
  }
}

export const chatApi = async (url, data, returnError) => {
  try {
    const res = await apiService.post(url, data);
    return res.data;
  } catch (err) {
    if (returnError) {
      if (err.response && err.response.data && err.response.data.errors) {
        return { error: err.response.data.errors }
      }
    } else {
      handleErrors(err);
    }
  }
};

export const chatDirectApi = async (data) => {
  try {
    const res = await apiService.post("/api/rest/v1/chat/direct", data);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const changeCountryApi = async (countryCode) => {
  try {
    const res = await apiService.get(`/api/rest/v1/account/address/add/?country=${countryCode}`);
    return res.data;
  } catch (err) {
    handleErrors(err);
  }
};

export const getAddressByPincodeApi = async (pinCode, hideError) => {
  try {
    const res = await apiService.get(`/api/rest/v1/get-address-by-postal-code/?postal_code=${pinCode}`);
    return res.data;
  } catch (err) {
    if (!hideError) handleErrors(err);
  }
};

export const getOtherAddressessApi = async () => {
  try {
    const res = await apiService.get("/api/rest/v1/account/address/");
    return res.data;
  } catch (err) {
    if (!hideError) handleErrors(err);
  }
};

export const changeCurrentAddressApi = async (pk) => {
  try {
    await apiService.post(`/api/rest/v1/cart/update-address-by-pk/`, { pk });
    return true;
  } catch (error) {
    handleErrors(error);
  }
};

export const getPaymentContext = async (token) => {
  try {
    const res = await apiService.post(`/api/rest/v1/orders/${token}/get-payment-context/next/`);
    return { result: res.data };
  } catch (error) {
    if (
      error.response && error.response.data &&
      error.response.data.error_msg) {
      return { error: error.response.data.error_msg };
    }
    return { error: "OOPS! Something went wrong, Try Again." }
  }
}

export const cancelOrderApi = async (token) => {
  try {
    await apiService.get(`/api/rest/v1/orders/${token}/cancel-request/`);
    return true;
  } catch (err) {
    handleErrors(err);
  }
};

export const bookAnAppointmentApi = async (data) => {
  try {
    const res = await apiService.post("/api/rest/v1/booking/", data);
    return res.data;
  } catch (err) {
    handleErrors(err);
  }
}

export const getReferralCodeApi = async () => {
  try {
    const res = await apiService.get(`/api/rest/v1/referral/`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
}

export const contactUsApi = async (data) => {
  try {
    const res = await apiService.post('/api/rest/v1/contact-us/', data);
    return true;
  } catch (err) {
    handleErrors(err);
  }
}